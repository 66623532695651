import request from "../utils/request";

// 登录
export const login = (params) => {
  return request({
    method: "GET",
    url: "/user/doLogin",
    params
  });
};


// 登出
export const logout = () => {
  return request({
    method: "GET",
    url: "/user/logout"
  });
};


// 修改密码
export const updateModify = (data) => {
  return request({
    method: "POST",
    url: "/user/updateModify",
    data
  });
};

// 客户信息
export const getCustomerList = (data) => {
  return request({
    method: "POST",
    url: "/customer/getCustomerList",
    data
  });
};

// 客户信息
export const addCustomer = (data) => {
  return request({
    method: "POST",
    url: "/customer/addCustomer",
    data
  });
};

// 客户信息
export const deleteCustomer = (params) => {
  return request({
    method: "GET",
    url: "/customer/deleteCustomer",
    params
  });
};


// 用户信息
export const getUserList = (data) => {
  return request({
    method: "POST",
    url: "/user/getUserList",
    data
  });
};

// 修改用户信息
export const updateUser = (data) => {
  return request({
    method: "POST",
    url: "/user/updateUser",
    data
  });
};


// 删除用户信息
export const deleteUser = (params) => {
  return request({
    method: "GET",
    url: "/user/deleteUser",
    params
  });
};

// 新增用户信息
export const addUser = (data) => {
  return request({
    method: "POST",
    url: "/user/addUser",
    data
  });
};


// 用户信息
export const getProjInfoList = (data) => {
  return request({
    method: "POST",
    url: "/projInfo/getProjInfoList",
    data
  });
};

// 新增用户信息
export const addProjInfo = (data) => {
  return request({
    method: "POST",
    url: "/projInfo/addProjInfo",
    data
  });
};

// 修改用户信息
export const updateProjInfo = (data) => {
  return request({
    method: "POST",
    url: "/projInfo/updateProjInfo",
    data
  });
};

// 修改项目状态
export const updateProjInfoFlag = (data) => {
  return request({
    method: "POST",
    url: "/projInfo/updateProjInfoFlag",
    data
  });
};
// 删除用户信息
export const deleteProjInfo = (params) => {
  return request({
    method: "GET",
    url: "/projInfo/deleteProjInfo",
    params
  });
};

//上传图片
export const uploadImg = (data) => {
  return request({
    method: "POST",
    url: "/file/uploadFile",
    data
  });
};

//查询业务员绑定项目模板
export const getUserProject = (params) => {
  return request({
    method: "GET",
    url: "/userProj/getUserProj",
    params
  });
};


//业务员绑定项目模板
export const addUserProject = (data) => {
  return request({
    method: "POST",
    url: "/userProj/addUserProj",
    data
  });
};
