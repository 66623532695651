/**
* 基础菜单 商品管理
*/
<template>
  <div>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search">
      <el-form-item label="用户名：">
        <el-input size="small" v-model="formInline.username" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input size="small" v-model="formInline.phone" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="getAccounts">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <div style="margin-bottom:20px;">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="resetForm('editForm')">添加用户</el-button>
      </div>
    </el-row>
    <!--列表-->
    <div class="sheInfo">
      <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
        element-loading-text="拼命加载中" style="width: 100%;">
        <el-table-column type="index" label="序号" align="center" :index="indexMethod">
        </el-table-column>
        <el-table-column align="center" prop="account" label="账户信息">
        </el-table-column>
        <el-table-column align="center" prop="username" label="用户名">
        </el-table-column>
        <el-table-column align="center" prop="phone" label="手机号">
        </el-table-column>
        <el-table-column align="center" prop="sex" label="性别">
          <template slot-scope="scope">
            <div v-if="scope.row.sex == 1">
              男
            </div>
            <div v-else>
              女
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="roleName" label="角色">
        </el-table-column>
        <el-table-column align="center" prop="wchartNickName" label="微信昵称">
        </el-table-column>
        <el-table-column align="center" prop="wchartNumber" label="微信手机号">
        </el-table-column>
        <el-table-column align="center" prop="wchartQcode" label="微信二维码">
          <template slot-scope="scope">
            <div class="demo-image">
              <el-image v-if="scope.row.wchartQcode" style="width: 40px; height: 40px" :src="scope.row.wchartQcode"
                fit="contain"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="wchartAvatar" label="微信头像">
          <template slot-scope="scope">
            <div class="demo-image">
              <el-image v-if="scope.row.wchartAvatar" style="width: 40px; height: 40px" :src="scope.row.wchartAvatar"
                fit="contain"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column align="center" label="操作" min-width="120">
          <template slot-scope="scope" v-if="scope.row.account !== 'admin'">
            <el-button size="mini" type="primary" plain icon="el-icon-edit" @click="editUser(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" plain icon="el-icon-delete" @click="delUser(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 添加界面 -->
    <el-dialog title="添加" :visible.sync="editFormVisible" width="30%" @closed="closeDialog('editForm')">
      <el-form label-width="90px" :model="editForm" :rules="rules" ref="editForm">
        <el-form-item label="账户名" prop="account">
          <el-input size="small" v-model="editForm.account" auto-complete="off" placeholder="请输入账户名"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input size="small" v-model="editForm.username" auto-complete="off" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input size="small" v-model="editForm.phone" auto-complete="off" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input size="small" v-model="editForm.password" auto-complete="off" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="微信头像" prop="wchartAvatar">
          <el-upload class="avatar-uploader" action="" accept="image/png,image/jpeg,image/jpg,image/BMP,image/jpg"
            :show-file-list="false" :http-request="addImageAvatar">
            <el-image v-if="editForm.wchartAvatar" style="width: 50px; height: 50px" :src="editForm.wchartAvatar"
              class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="微信二维码" prop="wchartQcode">
          <el-upload class="avatar-uploader" action="" :show-file-list="false" :http-request="addImageQcode">
            <el-image v-if="editForm.wchartQcode" :limit="1" style="width: 50px; height: 50px"
              :src="editForm.wchartQcode" class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio v-model="editForm.sex" label="1">男</el-radio>
          <el-radio v-model="editForm.sex" label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="角色" prop="roleId">
          <el-select v-model="editForm.roleId" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目模板" prop="projId">
          <el-select v-model="editForm.projId" placeholder="请选择">
            <el-option v-for="item in projList" :key="item.id" :label="item.title" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog('editForm')">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title" @click="submitForm()">保存
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑界面 -->
    <el-dialog title="编辑" :visible.sync="editFormVisibleEdit" width="40%">
      <el-form label-width="120px" :model="editFormEdit" :rules="rules" ref="editFormEdit">
        <el-form-item label="账户名" prop="account">
          <el-input size="small" v-model="editFormEdit.account" auto-complete="off" placeholder="请输入账户名"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input size="small" v-model="editFormEdit.username" auto-complete="off" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input size="small" v-model="editFormEdit.phone" auto-complete="off" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input size="small" v-model="editFormEdit.password" auto-complete="off" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="微信头像" prop="wchartAvatar">
          <el-upload class="avatar-uploader" action="" accept="image/png,image/jpeg,image/jpg,image/BMP,image/jpg"
            :show-file-list="false" :http-request="editImageAvatar">
            <el-image v-if="editFormEdit.wchartAvatar" style="width: 50px; height: 50px"
              :src="editFormEdit.wchartAvatar" class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="微信二维码" prop="wchartQcode">
          <el-upload class="avatar-uploader" action="" accept="image/png,image/jpeg,image/jpg,image/BMP,image/jpg"
            :show-file-list="false" :http-request="editImageQcode">
            <el-image v-if="editFormEdit.wchartQcode" :limit="1" style="width: 50px; height: 50px"
              :src="editFormEdit.wchartQcode" class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio v-model="editFormEdit.sex" label="1">男</el-radio>
          <el-radio v-model="editFormEdit.sex" label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="角色" prop="roleId">
          <el-select v-model="editFormEdit.roleId" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目模板" prop="projId">
          <el-select v-model="editFormEdit.projId" placeholder="请选择">
            <el-option v-for="item in projList" :key="item.id" :label="item.title" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialogEdit('editFormEdit')">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title" @click="submitFormEdit()">保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import Template from "@/components/template";
import { getUserList, deleteUser, updateUser, addUser, uploadImg, getProjInfoList, addUserProject } from "@/api/index";
import { validPhone, validAccount, validName, validPassword } from '@/utils/validate';
import { md5 } from 'js-md5';
export default {
  name: "account",
  data() {
    // 自定义验证规则
    var checkPhone = (rule, value, callback) => {
      if (!validPhone(value)) {
        return callback(new Error('手机号格式不正确'));
      } else {
        callback();
      }
    };
    var checkAccount = (rule, value, callback) => {
      if (!validAccount(value)) {
        return callback(new Error('账户名格式不正确，请填写6-16位字母、数字、下划线组合'));
      } else {
        callback();
      }
    };
    var checkName = (rule, value, callback) => {
      if (!validName(value)) {
        return callback(new Error('用户名格式不正确，请填写2-16位中文'));
      } else {
        callback();
      }
    };
    var checkPassword = (rule, value, callback) => {
      if (!validPassword(value)) {
        return callback(new Error('密码格式不正确，请填写6-16位字母、数字、下划线组合'));
      } else {
        callback();
      }
    };

    return {
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      editFormVisibleEdit: false, //控制编辑页面显示与隐藏
      editForm: {
        account: '',
        username: '',
        phone: '',
        password: '',
        sex: '1',
        wchartAvatar: '',
        wchartQcode: '',
        roleId: '',
        projId: ''
      },
      editFormEdit: {
        id: '',
        account: '',
        username: '',
        phone: '',
        password: '',
        sex: '',
        wchartAvatar: '',
        wchartQcode: '',
        roleId: '',
        projId: ''
      },
      options: [{
        value: '1',
        label: '管理员'
      }, {
        value: '2',
        label: '业务员'
      }],
      projList: [],
      // rules表单验证
      rules: {
        account: [{ required: true, message: '请输入账号', trigger: 'blur' },
        { validator: checkAccount, trigger: 'blur' }],
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' },
        { validator: checkName, trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' },
        { validator: checkPhone, trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' },
        { validator: checkPassword, trigger: 'blur' }],
        roleId: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        projId: [{ required: true, message: '请选择项目模板', trigger: 'blur' }]
      },
      formInline: {
        page: 1,
        limit: 10,
        username: '',
        phone: ''
      },
      userparm: [], //搜索权限
      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10
      }
    }
  },
  // 注册组件
  components: {
    Template,
    Pagination
  },
  /**
   * 创建完毕
   */
  created() {
    this.getAccounts()
    this.getProjInfos()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 列表序号
    indexMethod(index) {
      return index + 1;
    },
    //获取项目信息
    getProjInfos() {
      var _this = this;
      _this.loading = true
      let data = {
        pageNum: 1,
        pageSize: 9999,
      };
      getProjInfoList(data).then(function (res) {
        if (res.data.code == 0) {
          _this.projList = res.data.data.list;
        }
        console.log(_this.projList);
      }).catch(function (error) {
        console.log(error);
      });
    },
    //上传头像
    addImageAvatar(file) {
      let _this = this;
      let formData = new FormData();
      formData.append('file', file.file);
      uploadImg(formData).then(function (res) {
        console.log(res);
        if (res.data.code == 0) {
          _this.editForm.wchartAvatar = res.data.data;
          _this.$Message.success(res.data.message);
        } else {
          _this.$Message.error(res.data.message);
        }
      }).catch(function (error) {
        console.log(error);
      });
      console.log(_this.editForm);
    },
    //上传二维码
    addImageQcode(file) {
      let _this = this;
      let formData = new FormData();
      formData.append('file', file.file);
      uploadImg(formData).then(function (res) {
        if (res.data.code == 0) {
          _this.editForm.wchartQcode = res.data.data;
          _this.$Message.success(res.data.message);
        } else {
          _this.$Message.error(res.data.message);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },

    //上传头像
    editImageAvatar(file) {
      let _this = this;
      let formData = new FormData();
      formData.append('file', file.file);
      uploadImg(formData).then(function (res) {
        console.log(res);
        if (res.data.code == 0) {
          _this.editFormEdit.wchartAvatar = res.data.data;
          _this.$Message.success(res.data.message);
        } else {
          _this.$Message.error(res.data.message);
        }
      }).catch(function (error) {
        console.log(error);
      });
      console.log(_this.editForm);
    },
    //上传二维码
    editImageQcode(file) {
      let _this = this;
      let formData = new FormData();
      formData.append('file', file.file);
      uploadImg(formData).then(function (res) {
        if (res.data.code == 0) {
          _this.editFormEdit.wchartQcode = res.data.data;
          _this.$Message.success(res.data.message);
        } else {
          _this.$Message.error(res.data.message);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 获取用户列表
    getAccounts() {
      var _this = this;
      _this.loading = true
      let data = {
        pageNum: _this.formInline.page,
        pageSize: _this.formInline.limit,
        username: _this.formInline.username,
        phone: _this.formInline.phone
      };
      getUserList(data).then(function (res) {
        _this.$message({
          type: 'success',
          message: '获取用户信息成功'
        });
        _this.loading = false;
        _this.listData = res.data.data.list;
        _this.pageparm.total = parseInt(res.data.data.total);
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage
      this.formInline.limit = parm.pageSize
      this.getAccounts(this.formInline)
    },
    //删除用户
    delUser(data) {
      var _this = this;
      _this.loading = true;
      let param = {
        userId: data.id
      };
      _this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteUser(param).then(function (res) {
          if (res.data.code == 0) {
            _this.$message({
              type: 'success',
              message: res.data.message
            });
          } else {
            _this.$message({
              type: 'info',
              message: res.data.message
            });
          }
          _this.loading = false;
          _this.getAccounts(_this.formInline)
        });
      }).catch(() => {
        _this.loading = false;
        _this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //修改弹框
    editUser(data) {
      this.editFormVisibleEdit = true
      this.editFormEdit = data;
    },
    // 编辑、增加页面保存方法
    submitFormEdit() {
      var _this = this;
      _this.$refs.editFormEdit.validate(valid => {
        if (valid) {
          _this.loading = true;
          let param = _this.editFormEdit
          param.password = md5(param.password)
          updateUser(param).then(function (res) {
            if (res.data.code == 0) {
              _this.$message({
                type: 'success',
                message: res.data.message
              })
              let param ={
                projId: _this.editFormEdit.projId,
                userId: _this.editFormEdit.id
              }
              _this.addUserProjInfo(param)
            } else {
              _this.$message({
                type: 'error',
                message: res.data.message
              })
            }
            _this.loading = false;
            _this.editFormVisibleEdit = false
            _this.getAccounts()
          }).catch(function (error) {
            console.log(error);
          });
        }
      })
    },

    submitForm() {
      var _this = this;
      _this.$refs.editForm.validate(valid => {
        if (valid) {
          _this.loading = true;
          let data = _this.editForm;
          data.password = md5(data.password)
          addUser(data).then(function (res) {
            if (res.data.code == 0) {
              _this.$message({
                type: 'success',
                message: '添加成功'
              })
              let param ={
                projId: _this.editForm.projId,
                userId: res.data.data
              }
              _this.addUserProjInfo(param)
            } else {
              _this.$message({
                type: 'error',
                message: res.data.message
              })
            }
            _this.loading = false;
            _this.editFormVisible = false
            _this.getAccounts()
          }).catch(function (error) {
            console.log(error);
          });
        }
      })
    },
    //业务员添加模板调用
    addUserProjInfo(data) {
      var _this = this;
      addUserProject(data).then(function (res) {
        if (res.data.code != 0) {
          _this.$message({
            type: 'error',
            message: '模板启用失败'
          });
        }
      })
    },
    //新增弹框清空输入框 
    resetForm(formName) {
      this.editFormVisible = true;
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      })
      this.getAccounts()
    },
    // 关闭编辑、增加弹出框
    closeDialog(formName) {
      this.editFormVisible = false;
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      })
      this.getAccounts()
    },
    closeDialogEdit(formName) {
      this.editFormVisibleEdit = false
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      })
      this.getAccounts()
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

@media only screen and (max-width: 1440px) {
  .sheInfo .el-button--mini {
    padding: 7px 7px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #737579;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.avatar {
  width: 50px;
  height: 50px;
  display: block;
}
</style>
