/**
* 头部菜单
*/
<template>
  <el-menu class="el-menu-demo" mode="horizontal" background-color="#334157" text-color="#fff" active-text-color="#fff">
    <span style="font-size:20px;color:#fff;padding:0 0 0 0%;margin:0;float:left;line-height:60px;">分销管理系统</span>
    <el-submenu index="2" class="submenu" style="float: right;">
      <template slot="title">当前用户：{{ user.username }}</template>
      <el-menu-item @click="modify()" index="2-2">修改密码</el-menu-item>
      <el-menu-item @click="exit()" index="2-3">退出</el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>

import { logout } from "@/api/index";
export default {
  name: 'navcon',
  data() {
    return {
      user: {},
    }
  },
  // 创建完毕状态(里面是操作)
  beforeCreate() {
    this.$nextTick(() => {
       const users = JSON.parse(sessionStorage.getItem('userInfo'))
       this.user = users
    })
  },
  mounted() {
  },
  methods: {
    modify() {
      this.$router.push({ path: '/modify' });
    },
    // 退出登录
    exit() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        logout().then(res => {
          if (res.data.code == '0') {
            this.$store.commit('logout', 'false')
            this.$router.push({ path: '/login' })
            this.$message({
              type: 'success',
              message: '已退出登录!'
            })
          } else {
            _this.$message({
              type: 'error',
              message: res.data.response.errinfo
            })
          }
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
}

.submenu {
  float: right;
}

.buttonimg {
  height: 60px;
  background-color: transparent;
  border: none;
}

.showimg {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 17px;
  left: 17px;
}

.showimg:active {
  border: none;
}
</style>
