import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import echarts from 'echarts'
import axios from 'axios'
import Cookies from 'js-cookie'
import md5 from 'js-md5'
import VueEditor from 'vue2-editor'

Vue.prototype.$md5 = md5;
Vue.prototype.$axios = axios;
Vue.prototype.$echarts = echarts;
Vue.prototype.$Cookies = Cookies;

Vue.use(VueEditor)
Vue.use(ElementUI)

Vue.config.productionTip = false


Vue.prototype.HOST = process.env.VUE_APP_BASE_API;

axios.defaults.withCredentials = true;

// 路由拦截器
router.beforeEach((to, from, next) => {
  if (Boolean(Cookies.get("satoken"))) { // 通过vuex state获取当前的user是否存在
    if (to.path != "/" && to.path != "/login") { //判断是否要跳到登录界面
      next();
    } else {
      /**
       * 防刷新，如果登录，修改路由跳转到登录页面，修改路由为登录后的首页
       */
      next("/user_information")
    }
  } else if (to.path == "/login") { //判断是否要跳到登录界面
    next();
  } else {
    next("/login")
  }
})

new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>',
  data: {
    // 空的实例放到根组件下，所有的子组件都能调用
    Bus: new Vue()
  },
  render: h => h(App)
}).$mount('#app')
