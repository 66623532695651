<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px" style="width:40%;margin-top:20px;">
      <el-form-item label="原密码" prop="oldPassword">
        <el-input placeholder="请输入原密码" show-password v-model="form.oldPassword"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input placeholder="请输入新密码" show-password v-model="form.newPassword"></el-input>
      </el-form-item>
      <el-form-item label="确认输入" prop="confirmPassword">
        <el-input placeholder="请再次输入" show-password v-model="form.confirmPassword"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { md5 } from 'js-md5';
import { validPassword } from '@/utils/validate';
import {logout,updateModify} from '@/api/index';
import Cookies from 'js-cookie';
export default {
  name: "modify",
  data() {
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入', trigger: 'blur' }
        ]
      },
    }
  },
  methods: {
    onSubmit() {
      var _this = this;
      _this.loading = true;
      var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

      if (userInfo.password != md5(_this.form.oldPassword)) {
        _this.$message({
          type: 'error',
          message: '原密码错误'
        });
        return false;
      }

      if (!validPassword(_this.form.newPassword)) {
        _this.$message({
          type: 'error',
          message: '密码长度应为 8~16,至少包含一个字母和一个数字'
        });
        return false;
      }

      if (md5(_this.form.newPassword) === userInfo.password) {
        _this.$message({
          type: 'error',
          message: '新密码不能与原密码相同'
        });
        return true;
      }

      if (_this.form.newPassword !== _this.form.confirmPassword) {
        _this.$message({
          type: 'error',
          message: '两次输入的密码不一致'
        });
        return true;
      }

      let param = {
        password: md5(_this.form.oldPassword),
        newPassword: md5(_this.form.newPassword),
        id: userInfo.id
      };
      updateModify(param).then(function (res) {
        if (res.data.code === 0) {
          _this.$message({
            type: 'success',
            message: '修改成功,请用新密码重新登录'
          });
          logout().then(res => {
            Cookies.remove("satoken");
            if (res.data.code == 0) {
              _this.$store.commit('logout', 'false')
              _this.$router.push({ path: '/login' })
            }
          });
        } else {
          _this.$message({
            type: 'error',
            message: res.data.response
          })
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped></style>
