<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-row>
          <!-- 搜索筛选 -->
          <el-form :inline="true" v-model="formInline" class="user-search">
            <el-form-item label="项目标题：">
              <el-input size="small" v-model="formInline.title" placeholder="请输入项目标题" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getProjInfos">搜索</el-button>
            </el-form-item>
          </el-form>
          <el-row v-if="collapsed">
            <div style="margin-bottom:20px;">
              <el-button size="small" type="primary" icon="el-icon-plus" @click="resetForm()">添加项目</el-button>
            </div>
          </el-row>
          <!--列表-->
          <el-row>
            <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
              element-loading-text="拼命加载中" style="width: 100%;">
              <el-table-column type="index" label="序号" align="center" :index="indexMethod">
              </el-table-column>
              <el-table-column align="center" prop="title" label="项目标题">
              </el-table-column>
              <el-table-column align="center" prop="content" :show-overflow-tooltip="true" label="项目内容">
              </el-table-column>
              <el-table-column align="center" prop="createTime" label="创建时间" sortable>
              </el-table-column>
              <el-table-column align="center" label="操作" max-width="80" v-if="collapsed">
                <template slot-scope="scope" >
                  <el-button v-if="userProjectData && (scope.row.id == userProjectData.projId)" size="mini" type="success" plain icon="el-icon-check" disabled>已启用</el-button>
                  <el-button v-else size="mini" type="primary" plain icon="el-icon-edit" @click="addUserProjInfo(scope.row)">启用</el-button>
                  <el-button size="mini" type="primary" plain icon="el-icon-edit" @click="editProjInfo(scope.row)">编辑</el-button>
                  <el-button size="mini" type="danger" plain icon="el-icon-delete" @click="delProjInfo(scope.row)">删除</el-button>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" max-width="80" v-else>
                <template  slot-scope="scope">
                  <el-button v-if="userProjectData && (scope.row.id == userProjectData.projId)" size="mini" type="success" plain icon="el-icon-check" disabled>已启用</el-button>
                  <el-button v-else size="mini" type="primary" plain icon="el-icon-edit" @click="addUserProjInfo(scope.row)">启用</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页组件 -->
            <Pagination :child-msg="pageparm" @callFather="callFather"></Pagination>
          </el-row>
        </el-row>
      </el-col>
    </el-row>
    <!-- 添加界面 -->
    <el-dialog :title="titleDialog"  :visible.sync="editFormVisible" width="35%" :close-on-click-modal="false"
      @close="closeDialog()">
      <el-form ref="addForm" :model="addForm" label-width="100px">
        <el-form-item label="项目标题：">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="项目内容：" label-width="100px">
          <vue-editor style="max-height: 60vh; overflow-y: auto;" useCustomImageHandler @image-added="handleImageAdded"
            :editorToolbar="editorOption" v-model="addForm.content"></vue-editor>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog()">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title" @click="editOrAndSave()">保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Template from "../components/template";
import Pagination from "../components/Pagination";
import { getProjInfoList, addProjInfo, updateProjInfo, deleteProjInfo, uploadImg,getUserProject,addUserProject } from "@/api/index";
import { defaultBar } from '../utils/editorToolBar.js'
export default {
  name: "project_management",
  data() {
    return {
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      user: {},
      editorOption: defaultBar.modules.toolbar,
      collapsed: true,
      titleDialog: '',
      addForm: {
        title: '',
        content: ''
      },
      formInline: {
        title: ''
      },
      userProjectData:{},
      listData: [], //用户数据 
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10
      }
    }
  },
  // 注册组件
  components: {
    Template,
    Pagination
  },
  created() {
    const users = JSON.parse(sessionStorage.getItem('userInfo'))
    this.user = users
    if (users.roleDescription == 'admin') {
      this.collapsed = true
    } else {
      this.collapsed = false
    }
    this.getProjInfos()
    this.getUserProjectByuserId()
  },
  methods: {
    // 列表序号
    indexMethod(index) {
      return index + 1;
    },
    //业务员添加模板调用
    addUserProjInfo(data){
      var _this = this;
      _this.$confirm('是否启用此项目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
        userId: _this.user.id,
        projId: data.id
      }
      addUserProject(param).then(function (res) {
        if (res.data.code == 0) {
          _this.$message({
            type: 'success',
            message: '启用成功'
          });
        }
        _this.getUserProjectByuserId()
        _this.getProjInfos()
      })
      })
    },
    // 查询当前业务员的模板
    getUserProjectByuserId(){
      var _this = this; 
      let param = {
        userId: _this.user.id
      }
      getUserProject(param).then(function (res) {
        if (res.data.code == 0) {
          _this.userProjectData = res.data.data
        }
      })
    },
    //获取项目信息
    getProjInfos() {
      var _this = this;
      _this.loading = true
      let data = {
        pageNum: _this.formInline.page,
        pageSize: _this.formInline.limit,
        title: _this.formInline.title
      };
      getProjInfoList(data).then(function (res) {
        _this.$message({
          type: 'success',
          message: '获取项目信息成功'
        });
        _this.loading = false;
        _this.listData = res.data.data.list;
        _this.pageparm.total = parseInt(res.data.data.total);
      }).catch(function (error) {
        console.log(error);
      });
    },
    //新增或编辑 调用
    async editOrAndSave() {
      var _this = this;
      let data = _this.addForm;
      if (_this.titleDialog == '添加') {
        await _this.submitForm(data)
      } else {
        await _this.submitFormEdit(data)
      }
    },
    //新增
    async submitForm(data) {
      var _this = this;
      _this.loading = true;
      await addProjInfo(data).then(function (res) {
        if (res.data.code == 0) {
          _this.$message({
            type: 'success',
            message: '添加成功'
          })
          _this.editFormVisible = false
          _this.getProjInfos()
        } else {
          _this.$message({
            type: 'error',
            message: res.data.message
          })
        }
        _this.loading = false;
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 编辑
    submitFormEdit(data) {
      var _this = this;
      _this.loading = true;
      updateProjInfo(data).then(function (res) {
        if (res.data.code == 0) {
          _this.$message({
            type: 'success',
            message: res.data.message
          })
          _this.editFormVisible = false
          _this.getProjInfos()
        } else {
          _this.$message({
            type: 'error',
            message: res.data.message
          })
        }
        _this.loading = false
      }).catch(function (error) {
        console.log(error);
      });
    },
    //删除
    delProjInfo(data) {
      var _this = this;
      _this.loading = true;
      let param = {
        id: data.id
      };
      _this.$confirm('确定删除该项目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteProjInfo(param).then(function (res) {
          if (res.data.code == 0) {
            _this.$message({
              type: 'success',
              message: res.data.message
            });
          } else {
            _this.$message({
              type: 'info',
              message: res.data.message
            });
          }
          _this.loading = false;
          _this.getProjInfos()
        });
      }).catch(() => {
        _this.loading = false;
        _this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //上传图片
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append('file', file);
      uploadImg(formData).then(function (res) {
        if (res.data.code == 0) {
          Editor.insertEmbed(cursorLocation, "image", res.data.data);
          resetUploader();
        } else {
          this.$Message.error(res.data.message);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    //修改弹框
    editProjInfo(data) {
      this.titleDialog = '编辑'
      this.editFormVisible = true
      this.addForm = data
    },
    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage
      this.formInline.limit = parm.pageSize
      this.getProjInfos()
    },
    //新增弹框清空输入框 
    resetForm() {
      this.titleDialog = '添加'
      this.editFormVisible = true;
      this.addForm = {
        title: '',
        content: ''
      }
      this.$nextTick(() => {
        this.$refs.addForm.resetFields();
		  })
    },
    // 关闭编辑、增加弹出框
    closeDialog() {
      this.editFormVisible = false;
      this.$nextTick(() => {
        this.$refs.addForm.resetFields();
		  })
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}
</style>
