

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

//密码校验
export function validPassword(str) {
  const machete = /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*.])|(?=.*\d)(?=.*[#@!~%^&*.]))[a-z\d#@!~%^&*.]{8,16}/i;
  return machete.test(str)
}


//手机号校验
export function validPhone(str) {
  const machete = /^1[3456789]\d{9}$/;
  return machete.test(str)
}

// 用户名校验
export function validAccount(str) {
  const machete = /^[a-zA-Z0-9_-]{6,16}$/;
  return machete.test(str)
}

//姓名校验
export function validName(str) {
  const machete = /^[\u4e00-\u9fa5]{2,16}$/;
  return machete.test(str)
}