/**
* 左边菜单
*/
<template>
  <el-menu class="el-menu-vertical-demo" default-active="2" router unique-opened background-color="#334157"
    text-color="#fff" active-text-color="#ffd04b">
    <div class="logobox">
      <img class="logoimg" src="../assets/logo.png" alt="">
    </div>

    <el-menu-item index="/user_information" >
      <i class="iconfont el-icon-tickets"></i>
      <span slot="title">客户信息</span>
    </el-menu-item>
    <el-menu-item index="/project_management" v-if="collapsed">
      <i class="iconfont el-icon-upload2"></i>
      <span slot="title">项目管理</span>
    </el-menu-item>
    <el-menu-item index="/account" v-if="collapsed">
      <i class="iconfont el-icon-user" ></i>
      <span slot="title">账号管理</span>
    </el-menu-item>
    <el-menu-item index="/modify">
      <i class="iconfont el-icon-edit-outline"></i>
      <span slot="title">修改密码</span>
    </el-menu-item>
  </el-menu>
</template>
<script>
export default {
  name: 'leftnav',
  data() {
    return {
      collapsed: true
    }
  },
  created() {
    this.$nextTick(() => {
       const user = JSON.parse(sessionStorage.getItem('userInfo'))
       if(user.roleDescription == 'admin'){
         this.collapsed = true
       }else{
         this.collapsed = false
       }
    })
  }
}
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}

.el-menu-item-group__title {
  padding: 0px;
}

.el-menu-bg {
  background-color: #1f2d3d !important;
}

.el-menu {
  border: none;
}

.logobox {
  height: 40px;
  line-height: 40px;
  color: #9d9d9d;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px;
}

.logoimg {
  height: 40px;
}
.iconfont{
  margin-right: 5%;
}
</style>
