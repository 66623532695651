<template>
  <div>
    <el-row>
      <!-- 搜索筛选 -->
      <el-form :inline="true" v-model="formInline" class="user-search">
        <el-form-item label="微信昵称：">
          <el-input size="small" v-model="formInline.wchatNickName" placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input size="small" v-model="formInline.wchatPhone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="业务员：" v-if="collapsed">
          <el-input size="small" v-model="formInline.userName" placeholder="请输入姓名"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!--列表-->
    <el-row>
      <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
        element-loading-text="拼命加载中" style="width: 100%;">
        <el-table-column type="index" label="序号" align="center" :index="indexMethod">
        </el-table-column>

        <el-table-column align="center" prop="wchatNickName" label="微信昵称">
          <template slot-scope="scope">
            <div v-if="scope.row.wchatNickName">
              {{ scope.row.wchatNickName }}
            </div>
            <div v-else>
              微信用户
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column align="center" prop="wchatAvatar" label="微信头像">
          <template slot-scope="scope">
            <div class="demo-image">
              <el-image style="width: 40px; height: 40px" :src="scope.row.wchatAvatar" fit="contain"></el-image>
            </div>
          </template>
        </el-table-column> -->
        
        <el-table-column align="center" prop="wchatPhone" label="手机号">
        </el-table-column>

        <el-table-column align="center" prop="userName" label="业务员">
        </el-table-column>

        <el-table-column align="center" prop="createTime" label="创建时间" sortable>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <Pagination :child-msg="pageparm" @callFather="callFather"></Pagination>
    </el-row>
  </div>
</template>

<script>
import Template from "../components/template";
import Pagination from "../components/Pagination";
import { getCustomerList } from "../api/index";
export default {
  name: "user_information",
  data() {
    return {
      loading: false, //是显示加载
      newManage: false,
      websiteServer: '',
      websiteServers: [],
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      formInline: {
        pageNum: 1,
        pageSize: 10,
        userName: '',
        wchatPhone: '',
        wchatNickName: '',
        userId: ''
      },
      listData: []
    }
  },
  // 注册组件
  components: {
    Template,
    Pagination
  },
  created() {
    const users = JSON.parse(sessionStorage.getItem('userInfo'))
    if (users.roleDescription == 'admin') {
      this.collapsed = true
    } else {
      this.collapsed = false
      this.formInline.userId = users.id
    }
    this.getList();
  },
  methods: {
    //搜索
    search() {
      this.formInline.page = 1;
      this.getList();
    },
    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage
      this.formInline.limit = parm.pageSize
      this.loading = true;
      this.getList();
    },
    //表格序号
    indexMethod(index) {
      return index + 1;
    },
    //分页事件
    handleSizeChange(val) {
      this.formInlines.limit = val;
    },
    handleCurrentChange(val) {
      this.formInlines.page = val;
    },
    // //订单列表
    getList() {
      var _this = this;
      _this.loading = true;
      let param = {
        pageNum: _this.formInline.page,
        pageSize: _this.formInline.limit,
        userName: _this.formInline.userName,
        wchatPhone: _this.formInline.wchatPhone,
        wchatNickName: _this.formInline.wchatNickName,
        userId: _this.formInline.userId
      };
      getCustomerList(param).then(function (res) {
        _this.$message({
          type: 'success',
          message: '获取客户信息成功'
        });
        _this.loading = false;
        _this.listData = res.data.data.list;
        _this.pageparm.total = parseInt(res.data.data.total);
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}
</style>
