import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: false
  },
  getters: {

  },
  mutations: {
    // 登录
    login(state, status) {
      state.status = status;
      sessionStorage.setItem("loginState", state.status);
    },
    // 退出
    logout(state, stastatuste) {
      state.status = "";
      sessionStorage.removeItem("loginState");
      sessionStorage.removeItem("userInfo");
    }
  },
  actions: {
  },
  modules: {
  }
})
