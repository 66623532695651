<template>
  <div class="login-wrap">
    <el-form label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px"
      class="demo-ruleForm login-container">
      <h3 class="title">用户登录</h3>
      <el-form-item prop="account">
        <el-input type="text" v-model="ruleForm.account" auto-complete="off" placeholder="账号"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="ruleForm.password" auto-complete="off" placeholder="密码"></el-input>
      </el-form-item>
      <el-row>
      </el-row>
      <el-form-item style="width:100%;margin-bottom:10px;">
        <el-button type="primary" style="width:100%;" @click="submitForm('ruleForm')" :loading="logining">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script type="text/ecmascript-6">
import { login } from "@/api/index";
export default {
  name: 'Login',
  data() {
    return {
      //定义loading默认为false
      logining: false,
      ruleForm: {
        //account和password默认为空
        account: '',
        password: ''
      },
      //rules前端验证
      rules: {
        account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    // this.$message({
    //   message: '账号密码及验证码不为空即可',
    //   type: 'success'
    // })
  },
  // 里面的函数只有调用才会执行
  methods: {
    submitForm(formName) {
      var _this = this;
      this.$nextTick(() => {
        
		  
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.loading = true;
          let param = {
            account: _this.ruleForm.account,
            password: _this.ruleForm.password,
          };
          login(param).then(res => {
            if (res.data.code == '0') {
              _this.$store.commit('login', 'true');
              _this.$router.push({path: '/user_information'});

              _this.$message({
                type: 'success',
                message: '登录成功',
              });
              sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));
            } else {
              _this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          }).catch(function (error) {
            console.log(error);
          });
        } else {
          this.$message.error('请输入用户名密码！')
          this.logining = false
          return false
        }
      })
    })
    }
  }
}
</script>

<style scoped>
.login-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 10%;
  background-image: url("../assets/img/index.jpg");
  /* background-color: #112346; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@media only screen and (max-width: 1440px) {
  .login-wrap {
    padding-top: 6%;
  }
}

.tc {
  text-align: center;
}

.tc a {
  font-size: 14px;
  color: #333;
  text-decoration: none;
  margin-right: 10px;
}

.tc a:last-of-type {
  margin-right: 0;
}

.login-container {
  border-radius: 10px;
  margin: 0px auto;
  width: 350px;
  padding: 30px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: left;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}

.title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}

.remember {
  margin: 0px 0px 35px 0px;
}

.code-box {
  text-align: right;
}

.codeimg {
  height: 40px;
}
</style>
