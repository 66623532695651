import Vue from 'vue'
import VueRouter from 'vue-router'

// 登录
import login from '@/views/login';
// 用户管理
import index from '@/views/index';

// 修改密码
import modify from '@/views/modify';

import userInformation from '@/views/user_information';

import projectManagement from '@/views/project_management.vue';

import account from '@/views/account';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: login,
    hidden: true,
    meta: {
      requireAuth: false
    }
  }, {
    path: '/login',
    name: '登录',
    component: login,
    hidden: true,
    meta: {
      requireAuth: false
    }
  }, {
    path: '/index',
    name: '首页',
    component: index,
    iconCls: 'el-icon-tickets',
    meta: {
      requireAuth: true
    },
    children: [{
      path: '/user_information',
      name: '用户信息',
      component: userInformation,
      iconCls: 'el-icon-edit-outline',
      meta: {
        requireAuth: true
      }
    },{
      path: '/modify',
      name: '修改密码',
      component: modify,
      iconCls: 'el-icon-edit-outline',
      meta: {
        requireAuth: true
      }
    },{
      path: '/account',
      name: '用户管理',
      component: account,
      iconCls: 'el-icon-edit-outline',
      meta: {
        requireAuth: true
      }
    },{
      path: '/project_management',
      name: '项目管理',
      component: projectManagement,
      iconCls: 'el-icon-edit-outline',
      meta: {
        requireAuth: true
      }
    }
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  // base:  process.env.NODE_ENV !== 'development' ? '/distribution/' : '',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
